.svelte-jsoneditor-react {
    display: flex;
    flex: 1;
  }
  

.my-svelte-editor {
  /* width: 700px; */
  width: 100%;
  height: 400px;
  display: flex;
}

.my-svelte-editor-in-raw-json-tab-no-message {
  width: 100%;
  height: calc(70vh - 50px);
  display: flex;
}
.my-svelte-editor-in-raw-json-tab-with-message {
  width: 100%;
  height: calc(70vh - 320px); /*Don't go less than 320 px because the error panel sometimes is that tall. Or we'll have to add a scrollbar to the stuff at the bottom.*/
  display: flex;
}

.my-svelte-editor-in-modal {
  width: calc(90vw - 70px);
  height: calc(80vh - 200px);
  display: flex;
}

.my-svelte-editor-only-thing-in-modal {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
}

.my-svelte-editor-only-2-things-in-modal {
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
}