/* .editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
  }
  
  .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
  }
   */
.draftJSButtonWrapper {
    display: inline-block;
}

.draftJSButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 0px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
}
  
.draftJSButton:hover,
.draftJSButton:focus {
    background: #f3f3f3;
}


.rdw2-editor-main {
    overflow-y: scroll;
    padding: 10px;
}

/******************
The next three are around not for visuals, but so that when we click into the editor, we can click anywhere and still highlight the last line of text.
IF you remove the next three, you'll see that clicking in the editor doesn't highlight the last line of text.
********************/
.DraftEditor-root {
    height: 100%;
}
.DraftEditor-editorContainer {
    height: 100%;
}
.public-DraftEditor-content {
    height:100%;
}

/******************
Applies to tabs just above the Note Editor/Data/Chat.
******************/
.split-view-view .ant-tabs > .ant-tabs-nav {
    margin: 0px; /*remove the space between the tabs and the editor's toolbar/chat/data, saving 16px vertical space and looking cleaner too.*/
}


/* .rdw2-editor-main .public-DraftEditor-content {
} */
.editor blockquote {
    margin-left: 10px;
    border-left: 7px solid #f1f1f1;
    padding-left: 5px;
    font-family: sans-serif;
}

/*********
This unusual class name is for the undo/redo buttons
*********/
.b1lh9taq {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    /* padding-top: 5px; */
    vertical-align: bottom;
    height: 34px;
    width: 36px;
}
.b1lh9taq:hover {
    background: #f3f3f3;
}
.b1lh9taq:disabled { 
    color: #BBB;
}

.comment-block-title {
    position: relative;
    top: 0.9em;
    font-weight: bold;
    font-style: italic;
    color: #505050;
    padding-left: 0.4em;
    padding-right: 0.4em;
    margin-left: 1em;
    display: inline;
    background-color: rgb(231, 231, 231);
    border: #3c5a86 1px dashed;
}
.comment-block-content {
    background-color: rgb(231, 231, 231);
    border: #3c5a86 1px dashed;
    padding-left: 10px;
    
    /* The following is if we aren't using the block title only */
    /* padding-top: 10px; */
    margin-top: 10px;
    margin-bottom: 10px;

}

.blockquote-block > Button {
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 25px;
}