/********************
For React-Draft-WYSIWYG
*/
.editor {
  box-sizing: border-box;
  /* border: 1px solid #ddd; */
  cursor: text;
  /* padding: 16px; */
  /* border-radius: 2px; */
  /* margin-bottom: 2em; */
  /* box-shadow: inset 0px 1px 8px -3px #ABABAB; */
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.editorToolbar > div {
  display: inline-block;
}

.editor .demo-editor.rdw-editor-main{
  /* Numbers change depending on the toolbar... not sure what to put here to guarantee that it's scrolling right... we have extra padding at the bottom if we do that.
  Note that it's a bit shorter than the full page -- seems to need to be to force the scrollbar to not appear.*/
  height: calc(100vh - 250px);
  overflow: scroll;
}
.inline-editor-main {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.todoEditor .demo-editor.rdw-editor-main{
  overflow: scroll;
  height: 15vh;
}

/* .editorSplit .demo-editor.rdw-editor-main{ */
  /* overflow: scroll; */
  /* height: 65vh; */
/* } */

/* .prioritizationSidebar { */
  /* overflow: scroll; */
/* } */

.rdw-editor-toolbar {
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 100; */
  /* Increased margins may be helpful if, on NotePage, we set our siders to collapsedwidth of 0. */
  /* margin-left: 15px; */
  /* margin-right: 15px; */
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  /* The toolbar can become very tall if the page is narrow. We have to prevent that somehow.
  We can do better than this: */
  min-height: 50px;
  max-height: 100px;
  padding: 0px;
  background-color: #F8F8F8;
  border-width: 0px;
  /*Hiding the overflow would hide the dropdowns e.g. the color picker*/
  /*overflow: hidden;*/
}

.rdw-custom-button-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

/* .ant-layout-sider-zero-width-trigger { */
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 100; */
/* } */


/****************************
In-Editor Writing Styles
*****************************/
.editor h1 {
  font-size: 1.4em;
}
.editor h2 {
  font-size: 1.3em;
}
.editor h3 {
  font-size: 1.2em;
}
.editor h4 {
  font-size: 1.1em;
}
.editor h5 {
  font-size: 1em;
}
.editor h6 {
  font-size: 0.9em;
}

.editor h1 {
  font-size: 1.4em;
  font-weight: 900;
  
}
.editor h2 {
  font-size: 1.2em;
  font-weight: 700;
  margin-top: 40px;
}
.editor h3 {
  font-size: 1.1em;
  margin-top: 20px;
}

/********************
Checkable List Item

Attempting to convert https://github.com/sugarshin/draft-js-checkable-list-item/blob/master/src/CheckableListItem.styl
to regular CSS
*********************/
.checkable-list-item {
  list-style: none;
  transform: translateX(-1.5em);
}
.checkable-list-item-block__checkbox {
    position: absolute;
    z-index: 1;
    cursor: default;
}
.checkable-list-item-block__text {
    padding-left: 1.5em
}

/********************
Link To Note

*********************/
.link-to-note {
  list-style: none;
  transform: translateX(-3em);
  padding: 0px;
  padding-bottom: 5px;
}
.link-to-note-block__controls {
  display: inline-block;
  z-index: 1;
  cursor: default;
  padding: 0px;
  /* padding-right: 10px; */
  /* margin-right: 10px; */
  border-style: dashed;
  border-color: gray;
  border-width: 1px;

  /* border-radius: 10px; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.link-to-note-block__text {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  
  /* This is to be symmettrical with ant button in the left side. */
  padding-top: 5px;
  padding-bottom: 5px;
  
  /* padding-left: 5.5em */
}
.link-to-note-block__text .public-DraftStyleDefault-block {
  margin: 0px;
}

.link-to-note-link {
  user-select: none;
  display: inline-block;
  z-index: 1;
  padding: 0px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  margin: 0px;

  border-style: dashed;

  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-radius: 0px;

  height: 25px;
  /************
  Color -- should it be the same color as the hyperlink?
  *************/
  border-color: rgb(55, 170, 255);
  color: rgb(55, 170, 255);
  border-width: 1px;
}

/*****************
Narrated lines of text need to look a bit different from hyperlinks.
TODO we'd like a better distinctive style. The dotted underline -- is it enough? It looks a bit like a hyperlink.
*****************/
.narrated-text-line {
  display: inline-block;
  z-index: 1;
  padding: 0px;
  margin: 0px;

  border-style: dotted;

  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-radius: 0px;

  height: 25px;
  border-color: gray;
  border-width: 1px;
  color: gray;
}

/*****************
ChatWithDraft
*****************/
.chat-with-draft-user-message {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  margin-top: 20px;

  border-style: none;
  height: auto;
  white-space: normal;
  text-align: left;
  width: 100%;
  /* vertical-align: middle; */

  /* border-right-style: none; */
  /* border-left-style: none; */
  /* border-top-style: none; */
  /* border-radius: 5px; */

  /* height: 25px; */
  /* border-color: gray; */
  /* border-width: 1px; */
  color: gray;
  font-style: italic;
}