@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');


.gameCard, .gameCardBack {
    /* For the image, which will be included inline: */
    background-size: 100% 100%; /* This will stretch the image to the size.*/
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
}
.gameCard {
    border: 20px solid transparent;

    /* This would lighten up the picture. But that doesn't seem to enhance readability much, because we have just a subtle amount of transparency.
    It also makes the gameActionArea harder to read, because it reduces the contrast. */
    /* background-color: rgba(255,255,255,.5); */
    /* background-blend-mode: lighten; */
}

@media print {
    .gameCard, .gameCardBack {
        page-break-before: always;
        page-break-inside: avoid;
        /* For some reason the height seems to be miscalculating so we have to set a fixed height that works for 4x6 cards.
        Not sure why. */
        width: calc(100vw-80px);
        margin: 0px !important;
    }
    .gameCard {
        /* height: 650px; */
        height: calc(100vh - 40px);
    }
    .gameCardBack {
        /* height: 690px; */
        height: calc(100vh);
    }
 }
 @media not print {
    .gameCard {
        /* 4x6 format. A tiny bit smaller for gameCard than gameCardBack to account for the border*/
        /* (382+40=422) / (593+40=633) =1.5 so this is the perfect ratio for 4x6 down to the ~10th decimal place. */
        width: 382px;
        height: 593px;

        /* When printing to a card, we don't need border radius. But it might be nice to show on-screen. */
        border-radius: 7px; /*7px is the max without creating artifacts of double red*/
    }
    .cardDeckWithMargin .gameCard, .cardDeckWithMargin .gameCardBack{
        margin: 5px;
    }
    .gameCard, .gameCardBack {
        display: inline-block;
        /* margin: 5px; */
        margin: inherit;
        vertical-align: top;
    }  
    .gameCardBack {
        /* 4x6 format. It is slightly larger than the .gameCard to account for the 20 pixel width border on every side.*/
        /* Calc does not work on media queries. */
        /* width: calc(382px+40px); */
        /* height: calc(593px+40px); */
        width: 422px;
        height: 633px;
    }    
}

/* For attackActions */
.gameCard.attackAction {
    border-color: #450000; /* darker than the header color because this will be over an image, whereas that will not*/
}
.gameCard.attackAction .gameCardContents > .bottom .cost,
.gameCard.attackAction .gameCardContents > .bottom > .directions,
.gameCard.attackAction .header {
    background-color: #450000c8;
    color: white; /*white font on a darker background*/

}

/* For green/generative actions */
.gameCard.enhanceAction {
    border-color: #004500;
}
.gameCard.enhanceAction .gameCardContents > .bottom .cost,
.gameCard.enhanceAction .gameCardContents > .bottom > .directions,
.gameCard.enhanceAction .header {
    background-color: #004500c8;
    color: white; /*white font on a darker background*/
}

.gameCard.skillAction {
    border-color: #e6e6e6;
}
.gameCard.skillAction .gameCardContents > .bottom .cost,
.gameCard.skillAction .gameCardContents > .bottom > .directions,
.gameCard.skillAction .header {
    /* background-color: #ffffffcc; */
    background-color: #ffffffda;
    color: black; /*black font on a lighter background*/
}

.gameCard.player {
    border-color: #5a5a5a;
    background-color: #dedede;
}
.gameCard.player .headerRowLeftAligned .header, .gameCard.player .headerRowRightAligned .header {
    background-color: #5a5a5a;
    color: white; /*white font on a darker background*/
    flex: 1 1 auto;
}
.gameCard.playerUpgrades {
    border-color: lightgray;
    background-color: white;
}
.gameCard.playerUpgrades .headerRowLeftAligned .header, .gameCard.playerUpgrades .headerRowRightAligned .header {
    background-color: white;
    color: black;
    flex: 1 1 auto;
}
/* .gameCard.player .headerRowLeftAligned .headerEmptyColumn, .gameCard.playerUpgrades .headerRowLeftAligned .headerEmptyColumn,
.gameCard.player .headerRowRightAligned .headerEmptyColumn, .gameCard.playerUpgrades .headerRowRightAligned .headerEmptyColumn {
    flex: 0;
} */
.bodyStats {
    padding: 10px;
    padding-top: 0px;
    font-size: medium;
}
/* .gameCard.player .xpStats, .gameCard.player .goldStats, .gameCard.player .level {
} */
.gameCard.player .errors, .gameCard.playerUpgrades .errors {
    padding: 10px;
    border: 3px solid #000000;
    background-color: #fdff9e;
}
.lifeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.lifeRow .lifeHeader, .lifeRow .lifeIcons {
    margin-top: 0px;
    font-size: x-large;
}
.lifeRow .lifeHeader {
    /* Text align to the top, use the full height of the flex box: */
    align-self: flex-start;
    font-size: large;
    font-weight: bold;
    width: 20%;
    text-align: right;
    padding-right: 20px;
}
.lifeRow .lifeIcons {
    width: 80%;
}
.gameCard.player .miscContent {
    font-size: medium;
}


.gameActionArea {
    display: grid;
    grid-template-columns: repeat(9, 50px);
    grid-gap: 0;
}
.gameActionArea.larger {
    grid-template-columns: repeat(6, 60px);
}
.gameActionArea.smaller {
    grid-template-columns: repeat(7, 40px);
}
.gameActionArea.muchSmaller {
    grid-template-columns: repeat(12, 30px);
}
.gameActionAreaCell {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: medium;
}
.gameActionAreaCell.you {
    background-color: #f0f0f0d7;
    font-weight: bold;
}
.gameActionAreaCell.target {
    background-color: #febcbcda;
}
.gameActionAreaCell.empty {
    background-color: #ffffff60;
}
.gameActionAreaCell.larger {
    /* font-size: large; */
    width: 60px;
    height: 60px;
}
.gameActionAreaCell.smaller {
    font-size: small;
    width: 40px;
    height: 40px;
}
.gameActionAreaCell.muchSmaller {
    font-size: small;
    width: 30px;
    height: 30px;
}

.gameCard .headerRowLeftAligned {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.gameCard .headerRowRightAligned {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.gameCard .headerRowLeftAligned .header {
    font-family: 'Lexend', sans-serif;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 8px;
    padding-right: 40px;
    margin-bottom: 10px;
    flex: 0 1 auto;
    /* The width varies, which means the following clip-path doesn't do everything we want, but it's okay. */
    clip-path: polygon(0 0, 100% 0, calc(80%) 100%, 0 100%);
    /* Dynamic calculation to make the angle always be the same: */
    -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0 100%);
    text-align: left;
}
.gameCard .headerRowRightAligned .header {
    font-family: 'Lexend', sans-serif;
    padding-top: 0px;
    padding-left: 40px;
    padding-bottom: 8px;
    padding-right: 2px;
    margin-bottom: 10px;
    flex: 1 0 auto;
    /* The width varies, which means the following clip-path doesn't do everything we want, but it's okay. */
    clip-path: polygon(0 0, 100% 0, calc(80%) 100%, 0 100%);
    /* Dynamic calculation to make the angle always be the same: */
    /* -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0 100%); */
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%);
    text-align: right;
}

.gameCard .headerRowLeftAligned .headerEmptyColumn, .gameCard .headerRowRightAligned .headerEmptyColumn {
    flex: 1;
}
.gameCard .header .line1 {
    font-weight: bold;
    font-size: xx-large;
    display:block;
}
.gameCard.playerUpgrades .header .line1 {
    font-weight: bold;
    font-size: x-large;
    display:block;
}
.gameCard .header .line2 {
    font-weight: normal;
    font-size: large;
    display:block;
}




.gameCardContents {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* height: 100%; */
    /* Set the height to the max possible without going past the edge of the div: */
    height: calc(100% - 81px);
    justify-content: space-between;
}
.gameCardContents > .top {
    padding: 10px;
    align-self: flex-start;
}
.gameCardContents > .bottom {
    font-family: 'Lexend', sans-serif;

    display: inline-block;
    width: 100%;
    align-self: flex-end;
    align-content: flex-end;
}
.gameCardContents > .bottom .costRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.gameCardContents > .bottom .cost {
    font-size: large;
    font-weight: normal;
    padding: 10px;
    padding-right: 10px;
    padding-left: 40px;
    
    text-align: right;
    flex: 0 1 auto;
    clip-path: polygon(30px 0%, 100% 0, 100% 100%, 0% 100%);    
}
.gameCardContents > .bottom .costEmptyColumn {
    flex: 1;
}
.gameCardContents > .bottom > .directions {
    font-size: 20px;
    font-weight: normal;
    padding: 10px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 10px;
}
@media not print {
    .gameCardContents > .bottom > .directions {
        /* I'm not sure why we need this on screen. In print there's no problem. */
        margin-bottom: -1px;
    }
}
.gameCardContents > .bottom > .directions > .commandColorText {
    font-family: 'Calibri', sans-serif;
    font-style: italic;
    font-weight: lighter;
    color: rgba(255, 255, 255, .8);
}

@media print {
    .noprint {
        display: none;
        visibility: hidden;
    }
}
